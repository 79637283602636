<template>
	<div class="store">
		<div class="container">
			<div class="main-loading" v-if="!isContentReady">
				<loader-content :isDisplay="true"></loader-content>
			</div>
		</div>

		<template v-if="isContentReady">
			<store-detail :store="storeData" :isWithProduct="true" :topBanner="topBanner"></store-detail>
		</template>

		<div class="container">
			<div class="content-container mt-3">
				<div class="content-body">
					<template v-if="isContentReady">
						<template v-if="storeData.isRegisteredSeller">
							<div class="loading mt-3" v-if="!isResultReady">
								<div class="item">
									<loader-content :isDisplay="true"></loader-content>
								</div>
							</div>

							<div class="mt-3" v-if="isResultReady">
								<product-box-single
									:product="product"
									:store="storeData"
									:mode="constants.ITEM_BOX_TYPE.PURCHASE"
									@purchaseDelivery="openDeliveryChecker($event)"
									trackingReferrer="storeSingle">
								</product-box-single>
							</div>
						</template>
					</template>
				</div>
			</div>

			<modal-store-delivery-checker
				@close="closeDeliveryChecker()"
				@purchaseDelivery="openDeliveryChecker($event)"
				:isDisplay="isOpenDeliveryChecker"
				:product="deiveredProduct"
				:storeId="helper.splitDotParam($route.params.id)"
				trackingReferrer="storeSingle">
			</modal-store-delivery-checker>
		</div>
	</div>
</template>

<script>
import Helper from '@/services/helper';
import StoreService from '@/services/storeService';
import RouterPath from '@/router/path';
import Constants from '@/variables/constants';
import ProductBoxSingle from '@/components/product/ProductBoxSingle';
import StoreDetail from '@/components/store/StoreDetail';
import ModalStoreDeliveryChecker from '@/components/store/ModalDeliveryChecker';

export default {
	components: {
		ProductBoxSingle,
		StoreDetail,
		ModalStoreDeliveryChecker
	},
	data() {
		return {
			pageTitle: '',
			metaDesc: '',
			constants: Constants,
			helper: Helper,
			isContentReady: false,
			isResultReady: false,
			topBanner: null,
			storeData: null,
			product: null,
			deiveredProduct: null,
			isOpenDeliveryChecker: false,
			canonicalUrl: ''
		};
	},
	async mounted() {
		await Promise.all([this.getContent(), this.getProductResult()]);

		// SEO
		const productTitle = [ 'ยางรถยนต์', this.product.brand, 'รุ่น', this.product.name, this.product.size ].join(' ');
		this.pageTitle = this.storeData ? this.storeData.name + ' - ' + productTitle + ' | YELLOWTiRE' : Helper.metaDefault().title;
		this.metaDesc = this.storeData ? this.storeData.name + ' โทร.' + this.storeData.tel + ', ที่อยู่ ' + this.storeData.fullAdress + ' - ' + productTitle : Helper.metaDefault().desc;
	},
    methods: {
        async getContent() {
            this.isContentReady = false;

			const param = this.setupParamStore();
            const result = await StoreService.getStoreDetail(param);

			if (result.status == 404) {
				this.$router.push('/404');
			} else if (result.data) {
				this.canonicalUrl = location.protocol + "//" + window.location.host + RouterPath.STORE + '/' + decodeURI(result.data.storeData.param) + '/' + this.$route.params.spid;
				this.topBanner = result.data.ads;
				this.storeData = result.data.storeData;
			} else {
				this.$router.push(RouterPath.NOT_FOUND);
			}

			this.isContentReady = true;
        },
		async getProductResult() {
            this.isResultReady = false;

			const param = this.setParamProduct();
            const result = await StoreService.getSingleProduct(param);

			if (result.data.resultList.length > 0) {
				this.product = result.data.resultList[0];
			} else {
				this.$router.push('/404');
			}
			
            this.isResultReady = true;
        },
		setupParamStore() {
			const param = {
				id: Helper.splitDotParam(this.$route.params.id),
				urlReferrer: document.referrer
			};

			return param;
		},
		setParamProduct() {
			const param = {
				storeId: Helper.splitDotParam(this.$route.params.id),
				sellerProductId: this.$route.params.spid
			};

			return param;
		},
		openDeliveryChecker(data) {
			this.deiveredProduct = data;
			this.isOpenDeliveryChecker = true;
		},
		closeDeliveryChecker() {
			this.isOpenDeliveryChecker = false;
		}
    },
	metaInfo() {
		const image = this.storeData ? this.storeData.picture : location.origin + Helper.metaDefault().logo;
		
        return {
            title: this.pageTitle,
            meta: [
				{ vmid: 'author', name: 'author', content: Helper.metaDefault().author },
				{ vmid: 'publisher', name: 'publisher', content: Helper.metaDefault().publisher },
				{ vmid: 'keyword', name: 'keywords', content: Helper.metaDefault().keyword },
                { vmid: 'desc', name: 'description', content: this.metaDesc },
                { vmid: 'og-url', property: 'og:url', content: location.href },
                { vmid: 'og-title', property: 'og:title', content: this.pageTitle },
                { vmid: 'og-desc', property: 'og:description', content: this.metaDescsc },
                { vmid: 'og-type', property: 'og:type', content: 'website' },
                { vmid: 'og-image', property: 'og:image', content: image },
                { vmid: 'twitter-description', property: 'twitter:description', content: this.metaDesc },
                { vmid: 'twitter-title', property: 'twitter:title', content: this.pageTitle },
                { vmid: 'twitter-image', property: 'twitter:image', content: image }
            ],
			link: [
				{ rel: 'canonical', href: this.canonicalUrl }
			]
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.store {
	padding: 0 0 25px;

	.main-loading {
		height: 300px;
	}

	.loading {
		.item {
			height: 250px;
		}
	}
}
</style>
